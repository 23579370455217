export const FEEDBACK_TYPES = {
  CALL: 'call',
  PRODUCT_CONSULTATION: 'productConsultation',
  CONSULTATION: 'consultation',
  PROMO_SUBSCRIPTION: 'promoSubscription'
}

export const FEEDBACK_TYPES_DISPLAY = {
  [FEEDBACK_TYPES.CALL]: 'Звонок',
  [FEEDBACK_TYPES.PRODUCT_CONSULTATION]: 'Консультация по продукту',
  [FEEDBACK_TYPES.CONSULTATION]: 'Консультация',
  [FEEDBACK_TYPES.PROMO_SUBSCRIPTION]: 'Подписка на скидку'
}
