<template>
  <section :class="$style.subscriptions">
    <el-table :data="feedbacks" stripe>
      <el-table-column prop="createdAt" label="Дата оформления">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Тип">
        <template slot-scope="scope">
          {{ $options.FEEDBACK_TYPES_DISPLAY[scope.row.type] }}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="Телефон" />
      <el-table-column prop="email" label="Почта" />
      <el-table-column prop="source" label="Ссылка">
        <template slot-scope="scope">
          <adw-router :link="scope.row.source" target="_blank">{{
            scope.row.source
          }}</adw-router>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="feedbacks.length"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      background
      :class="$style.pagination"
      @current-change="getList"
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'

import { formatDate } from '@/helpers/index'
import { FEEDBACK_TYPES_DISPLAY } from '@/constants/feedBackTypes'
export default {
  FEEDBACK_TYPES_DISPLAY,
  data() {
    return {
      feedbacks: [],
      limit: 20,
      page: 1,
      total: 100
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'created_at',
        orderDir: 'desc'
      }

      const { value, error } =
        await delivery.NotificationServiceCore.FeedbacksActions.getList(query)

      loading.close()

      if (error) return

      this.feedbacks = value.data
      this.total = value.meta.count
    },
    formatDate(date) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" module>
.subscriptions {
  padding: 1rem;

  .pagination {
    @include pagination;
  }
}
</style>
